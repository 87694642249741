<template>
  <div class="no-content">
    <div class="center">
      <img :src="noContent">
      <p>暂无内容</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      noContent: require('images/no-content.png')
    }
  }
}
</script>

<style lang="scss" scoped>
  .no-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      margin-top: 6px;
    }
  }
</style>
